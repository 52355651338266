import React, {useCallback, useMemo} from 'react';
import { LegacyStack, RadioButton, Button, Text } from "@shopify/polaris";
import {BUTTON_STYLES, SELECTED_STYLE_BOX_SHADOW} from '../../../../../../constants/constants';
import update from 'immutability-helper';
import FieldTitle from '../../../../../../components/fieldTitle';
const ButtonBlockItem = (props) => {
  const {state, setState} = props;

  const checkButtonStyle = useCallback((styleName, value, defaultValue) => {
    if (state.ctaStyles
      && state.ctaStyles[styleName]) {
      return state.ctaStyles[styleName] === value;
    } else return value === defaultValue;
  }, [state]);

  const setChecked = useCallback((styleName, id) => {
    if (state?.ctaStyles && state?.ctaStyles.hasOwnProperty(`${styleName}`)) {
      return state?.ctaStyles[styleName] === id;
    } else {
      return styleName === BUTTON_STYLES[0].styleName ? id === 'inherit' : id === 'secondary';
    }
  }, [state]);

  const handleChange = useCallback((value, styleName) => {
    if (state?.ctaStyles) {
      if (state?.ctaStyles[styleName]) {
        setState(update(state, { ctaStyles: { [styleName]: { $set: value } }}));
      } else {
        setState(update(state, { ctaStyles: { $merge: { [styleName]: value } }}));
      }
    } else {
      setState(update(state, { $merge: { ctaStyles: { [styleName]: value }}}));
    }
  }, [state, setState]);

  const renderStyles = useCallback((options, styleName) => {
    switch (styleName) {
      case 'fill':
        return options.map(({id, color}, index) => {
          let buttonClasses = {};
          if (checkButtonStyle(styleName, id, 'inherit')) {
            buttonClasses.boxShadow = SELECTED_STYLE_BOX_SHADOW;
          }

          return (
            <div key={index} style={{textAlign:'center'}}>
              <div className='ColorButton' style={buttonClasses}>
                <Button onClick={() => handleChange(id, styleName)}>
                  <div style={{backgroundColor: `${color}`}} className='ColorButtonContent'/>
                </Button>
              </div>
              <span style={{display:'block'}}>{id}</span>
            </div>
          )
        })
      default:
        return options.map(({id, name, label}, index) => {
          return (
            <RadioButton
              id={id}
              key={index}
              label={label}
              name={name}
              checked={setChecked(name, id)}
              onChange={(e, value) => handleChange(value, styleName)}
            />
          )
        });
    }
  }, [state]);

  const renderBlocks = useMemo(() => {
    return BUTTON_STYLES.map(({styleName, styleTitle, options}, index) => {
      return (
        <div>
        <LegacyStack key={index} vertical={true} spacing='tight'>
          <FieldTitle title={styleTitle}/>
          <LegacyStack>
            {renderStyles(options, styleName)}
          </LegacyStack>
        </LegacyStack>
        </div>
      );
    });
  }, [state]);

  return (
    <LegacyStack vertical={true}>
      {renderBlocks}
    </LegacyStack>
  );
};

export default ButtonBlockItem;